import { Controller, useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import countryList from "../../_mockData/countries";
import ReactSelect from "react-select";
import {
  getFilteringClients,
  getFilteringProjects,
} from "../../api/commonApiCalls";
import { useAuth } from "../../hooks/useAuth";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import moment from "moment";
import LoadingPage from "../../Pages/LoadingPage/LoadingPage";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineCamera,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import ApiErrorMessage from "../error/ApiErrorMessage";
import ErrorMessageShow from "./ErrorMessageShow";
import { useDropzone } from "react-dropzone";

const employeeOption = [
  { label: "Intern", value: "Intern" },
  { label: "Fulltime", value: "Fulltime" },
  { label: "Part-time", value: "Parttime" },
  { label: "Contractual", value: "Contractual" },
];

const MAX_FILE_SIZE = 4000000;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 80,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const BasicInfoTab = ({
  onSubmit,
  employeeData,
  stepData,
  handleError,
  files,
  setFiles,
}) => {
  console.log(stepData, "employeeData:", employeeData);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedFilterClient, setSelectedFilterClient] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [branches, setBranches] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [designations, setDesignations] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAlt, setShowPasswordAlt] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const { token } = useAuth();

  const validationSchema = Yup.object().shape({
    attDevId: Yup.number()
      .required("Attendance Device ID is Requried")
      .typeError("Enter a number"),
    hrmEmployeeId: Yup.string().required("HRM Employee ID is Required"),
    joinDate: Yup.string().required("Joining Date is Required").nullable(),
    dobDate: Yup.string().required("Date of birth Date is Required").nullable(),
    presentAddress: Yup.string(),
    permanentAddress: Yup.string(),
    branch: Yup.string().required("Branch is Required"),
    shift: Yup.string().required("Office is required"),
    role: Yup.string().required("Role is required"),
    contract: Yup.string().required("Job Contract is required"),
    department: Yup.string().required("Department is required"),
    // designation: Yup.string(),
    designation: Yup.string().required("Designation is required"),
    // employeeType: Yup.string().required("Employee Type is required"),
    employeeName: Yup.string()
      .required("Employee name is required")
      .min(3, "Minimum 3 characters"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Minimum 8 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    // password:
    //   stepData !== null
    //     ? null
    //     : Yup.string()
    //         .required("Password is required")
    //         .min(8, "Minimum 8 characters"),
    // confirmPassword:
    //   stepData !== null
    //     ? null
    //     : Yup.string()
    //         .required("Confirm Password is required")
    //         .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  //react-hook-form
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  //file preview
  const thumbs = files?.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <Avatar
          src={file.preview}
          alt="Upload" // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          variant="square"
          sx={{ width: "100%", height: 1, minWidth: 100 }}
        />
        {/* <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        /> */}
      </div>
    </div>
  ));

  useEffect(() => {
    if (stepData !== null) {
      setValue("attDevId", stepData.att_div_id);
      setValue("hrmEmployeeId", stepData.hrm_employee_id);
      setValue("joinDate", stepData.join_date);
      setValue("dobDate", stepData.dob);
      setValue("branch", stepData.branch_id);
      setValue("employeeName", stepData.name);
      setValue("fatherName", stepData.father_name);
      setValue("motherName", stepData.mother_name);
      setValue("email", stepData.email);
      setValue("role", stepData.role_id);
      setValue("contract", stepData.job_contract_id);
      setValue("phone", stepData.phone);
      setValue("homePhone", stepData.home_phone);
      setValue("businessPhone", stepData.business_phone);
      setValue("country", stepData.country);
      setValue("city", stepData.city);
      setValue("designation", stepData.designation_id);
      setValue("department", stepData.department_id);
      setValue("employeeType", stepData.type);
      setValue("shift", stepData.office_shift_id);
      // setValue("eidClient", stepData.employee_client_id);
      setValue("presentAddress", stepData.present_address || "");
      setValue("permanentAddress", stepData.parmanent_address || "");
      setSelectedProject(stepData.project_id);
      setSelectedDepartment(
        departments?.filter(
          (department) => department?.value === stepData.department_id
        )[0]
      );
    }
  }, [stepData]);

  // useEffect(() => {
  //   reset({ ...employeeData });
  // }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  //get client list
  // useEffect(() => {
  //   getFilteringClients(setLoading, token, setClients);
  // }, []);
  //get project list
  // useEffect(() => {
  //   getFilteringProjects(
  //     setLoading,
  //     token,
  //     setProjects,
  //     selectedFilterClient?.value
  //   );
  // }, [selectedFilterClient]);

  //get all branch from api
  const getAllBranches = async () => {
    setLoading(true);
    try {
      const response = await Axios.get("branch/all", {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });
      setBranches(
        response.data.response_data?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //get all shifts from api
  const getAllShifts = async () => {
    setLoading(true);
    try {
      const response = await Axios.get("office-shift/all", {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });
      setShifts(
        response.data.response_data?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //get all roles from api
  const getAllRoles = async () => {
    setLoading(true);
    try {
      const response = await Axios.get("role/all", {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });
      setRoles(
        response.data.response_data?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //get all contracts from api
  const getAllContracts = async () => {
    setLoading(true);
    try {
      const response = await Axios.get("job-contract/all", {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });
      setContracts(
        response.data.response_data?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //get all contracts from api
  const getAllDepartments = async () => {
    // setLoading(true);
    try {
      const response = await Axios.get("department/all", {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });
      setDepartments(
        response.data.response_data?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  const getAllDesignations = async (departmentId) => {
    // setLoading(true);
    try {
      const response = await Axios.post(
        `/designation/department`,
        // `designation/all?department_id=${selectedDepartment.value}`,
        {
          department_id: selectedDepartment,
        },
        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setDesignations(
        response.data.response_data?.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      if (error.response.status === 422) {
        toast.warn(
          "Selected department does not have a designation associated with it. Please add a designation under this department first."
        );
      } else {
        console.log(error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };

  //get office schedule
  useEffect(() => {
    const getSchedule = async () => {
      setLoading(true);
      try {
        const res = await Axios.get(
          `employee/schedule?project_id=${selectedProject}`,
          {
            headers: {
              Authorization: token
                ? "Bearer " + token
                : "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.status === "success") {
          setSchedules(
            res.data.response_data?.map((schedule) => ({
              label: `${schedule?.shift} - ${moment(schedule?.start_time, [
                moment.ISO_8601,
                "HH:mm",
              ]).format("hh:mm A")} to ${moment(schedule?.end_time, [
                moment.ISO_8601,
                "HH:mm",
              ]).format("hh:mm A")}`,
              value: schedule.id,
            }))
          );
        } else {
          setSchedules([]);
          toast.warn("Office schedule is not available");
        }
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.message === "Data not found.") {
          toast.warn("Office schedule is not available for this project");
          setSchedules([]);
        } else {
          console.log(error);
        }
        setLoading(false);
      }
    };
    getAllBranches();
    getAllShifts();
    getAllRoles();
    getAllContracts();
    getAllDepartments();
  }, []);

  useEffect(() => {
    if (selectedDepartment !== null) {
      getAllDesignations(selectedDepartment?.value);
    }
  }, [selectedDepartment]);

  return loading ? (
    <LoadingPage />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justifyContent={"center"} sx={{ mb: 3 }}>
        <Grid item sm={4} xs={12}>
          <Stack justifyContent={"center"}>
            {/* <label htmlFor="uploadImage">
              <Box
                sx={{
                  width: 110,
                  height: 80,
                  background: "#02b19a",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <AiOutlineCloudUpload size={22} /> Upload{" "}
              </Box>
            </label> */}
            {/* <section
              className="container"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                flexWrap: "wrap",
              }}
            >
            
            </section> */}

            <section
              className="container"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                flexWrap: "wrap",
              }}
            >
              <div
                {...getRootProps({ className: "dropzone" })}
                style={{ position: "relative" }}
              >
                <input {...getInputProps()} />

                <Avatar
                  src={
                    files[0]?.preview ||
                    `${process.env.REACT_APP_LIVE_BASE_URL}/${stepData?.image}`
                  }
                  // alt={"Upload"}
                  sx={{
                    minWidth: 100,
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    right: "-8px",
                    bottom: "2px",
                    background: "#acacac",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "2px solid white",
                    cursor: "pointer",
                  }}
                >
                  <AiOutlineCamera size={24} color="white" />
                </Box>
                {/* {stepData?.image ? (
                  <Avatar
                    src={`${process.env.REACT_APP_LIVE_BASE_URL}/${stepData?.image}`}
                    alt={"Upload"}
                    variant="rounded"
                    sx={{
                      minWidth: 100,
                      width: "100px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <aside style={thumbsContainer}>{thumbs}</aside>
                )} */}
              </div>
            </section>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/*input for employee name*/}

        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.employeeName ? red[700] : ""}
                >
                  Employee Name*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.employeeName}
                  placeholder="Enter Employee Name"
                />
                {!!formState.errors?.employeeName ? (
                  <FormHelperText error>
                    {errors?.employeeName?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="employeeName"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input field for employee id*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.hrmEmployeeId ? red[700] : ""}
                >
                  HRM Employee ID*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.hrmEmployeeId}
                  placeholder="Enter Employee ID"
                />
                {!!formState.errors?.hrmEmployeeId ? (
                  <FormHelperText error>
                    {errors?.hrmEmployeeId?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="hrmEmployeeId"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input field for attendance device id*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.attDevId ? red[700] : ""}
                >
                  Attendance Device ID
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.attDevId}
                  placeholder="Enter Attendance Device ID"
                />
                {!!formState.errors?.attDevId ? (
                  <FormHelperText error>
                    {errors?.attDevId?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="attDevId"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input for father's name*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.fatherName ? red[700] : ""}
                >
                  Father Name
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.fatherName}
                  placeholder="Enter Father Name"
                />
                {!!formState.errors?.fatherName ? (
                  <FormHelperText error>
                    {errors?.fatherName?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="fatherName"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input for mother's name*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.motherName ? red[700] : ""}
                >
                  Mother Name
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.motherName}
                  placeholder="Enter Mother Name"
                />
                {!!formState.errors?.motherName ? (
                  <FormHelperText error>
                    {errors?.motherName?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="motherName"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input for phone*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography color={!!formState.errors?.phone ? red[700] : ""}>
                  Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.phone}
                  placeholder="Enter Phone Number"
                />
                {!!formState.errors?.phone ? (
                  <FormHelperText error>
                    {errors?.phone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="phone"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input for country*/}
        <Grid item lg={6} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography color={!!formState.errors?.country ? red[700] : ""}>
                  Country
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.country
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.country
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="country"
                  options={countryList}
                  getOptionValue={(option) => option.id}
                  placeholder={"Select Country"}
                  value={
                    field?.value !== ""
                      ? countryList?.filter(
                          (country) => country?.id === field?.value
                        )[0]
                      : {}
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.id)
                  }
                />
                {!!formState.errors?.country ? (
                  <FormHelperText error>
                    {errors?.country?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="country"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input for city*/}
        <Grid item lg={6} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth variant="outlined">
                <Typography color={!!formState.errors?.city ? red[700] : ""}>
                  City
                </Typography>
                <OutlinedInput
                  size={"small"}
                  {...field}
                  error={!!formState.errors?.city}
                  placeholder="Enter City"
                />
                {!!formState.errors?.city ? (
                  <FormHelperText error>{errors?.city?.message}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="city"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input for present address*/}
        <Grid item sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.presentAddress ? red[700] : ""}
                >
                  Present Address
                </Typography>
                <TextField
                  {...field}
                  error={!!formState.errors?.presentAddress}
                  multiline
                  rows={3}
                  placeholder="Enter Present Address"
                />
                {!!formState.errors?.presentAddress ? (
                  <FormHelperText error>
                    {errors?.presentAddress?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="presentAddress"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input for permanent address*/}
        <Grid item sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.permanentAddress ? red[700] : ""}
                >
                  Permanent Address
                </Typography>
                <TextField
                  {...field}
                  error={!!formState.errors?.permanentAddress}
                  multiline
                  rows={3}
                  placeholder="Enter Permanent Address"
                />
                {!!formState.errors?.permanentAddress ? (
                  <FormHelperText error>
                    {errors?.permanentAddress?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="permanentAddress"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*Dropdown for Branch*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography color={!!formState.errors?.branch ? red[700] : ""}>
                  Branch*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.branch
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.branch
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  options={branches}
                  getOptionValue={(option) => option.value}
                  placeholder={"Select Branch"}
                  value={
                    field?.value !== ""
                      ? branches?.filter(
                          (branch) => branch?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.branch ? (
                  <FormHelperText error>
                    {errors?.branch?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="branch"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*input field for joining date*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.joinDate ? red[700] : ""}
                >
                  Joining Date*
                </Typography>
                <OutlinedInput
                  {...field}
                  type={"date"}
                  error={!!formState.errors?.joinDate}
                  placeholder="Enter Attendance Device ID"
                />
                {!!formState.errors?.joinDate ? (
                  <FormHelperText error>
                    {errors?.joinDate?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="joinDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography color={!!formState.errors?.dobDate ? red[700] : ""}>
                  DOB*
                </Typography>
                <OutlinedInput
                  {...field}
                  type={"date"}
                  error={!!formState.errors?.dobDate}
                  placeholder="Enter Attendance Device ID"
                />
                {!!formState.errors?.dobDate ? (
                  <FormHelperText error>
                    {errors?.dobDate?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="dobDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*Dropdown for department*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography
                  color={!!formState.errors?.department ? red[700] : ""}
                >
                  Department*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.department
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.department
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  options={departments}
                  getOptionValue={(option) => option.value}
                  placeholder={"Select Department"}
                  value={
                    field?.value !== ""
                      ? departments?.filter(
                          (department) => department?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption?.value);
                    setSelectedDepartment(selectedOption);
                  }}
                />
                {!!formState.errors?.department ? (
                  <FormHelperText error>
                    {errors?.department?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="department"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*Dropdown for Designation*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography
                  color={!!formState.errors?.designation ? red[700] : ""}
                >
                  Designation*
                </Typography>
                <ReactSelect
                  isDisabled={selectedDepartment === null}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.designation
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.designation
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  options={designations}
                  getOptionValue={(option) => option.value}
                  placeholder={"Select Branch"}
                  value={
                    field?.value !== ""
                      ? designations?.filter(
                          (branch) => branch?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.designation ? (
                  <FormHelperText error>
                    {errors?.designation?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="designation"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*Dropdown for Job Contract*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography
                  color={!!formState.errors?.contract ? red[700] : ""}
                >
                  Job Contract*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.contract
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.contract
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  options={contracts}
                  getOptionValue={(option) => option.value}
                  placeholder={"Select Job Contract"}
                  value={
                    field?.value !== ""
                      ? contracts?.filter(
                          (contract) => contract?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.contract ? (
                  <FormHelperText error>
                    {errors?.contract?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="contract"
            control={control}
            defaultValue=""
          />
        </Grid>

        {/*Dropdown for Employee Type*/}
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth>
                <Typography
                  color={!!formState.errors?.employeeType ? red[700] : ""}
                >
                  Employee Type*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.employeeType
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.employeeType
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="employeeType"
                  options={employeeOption}
                  value={
                    field?.value !== ""
                      ? employeeOption?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : setValue("employeeType", "Fulltime")
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.employeeType ? (
                  <FormHelperText error>
                    {errors?.employeeType?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="employeeType"
            control={control}
          />
        </Grid> */}
        {/*Dropdown for Office Shift*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography color={!!formState.errors?.shift ? red[700] : ""}>
                  Office Shift*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.shift
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.shift
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  options={shifts}
                  getOptionValue={(option) => option.value}
                  placeholder={"Select Office Shift"}
                  value={
                    field?.value !== ""
                      ? shifts?.filter(
                          (shift) => shift?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.shift ? (
                  <FormHelperText error>
                    {errors?.shift?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="shift"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/*Dropdown for Role*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography color={!!formState.errors?.role ? red[700] : ""}>
                  Role*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.role
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.role
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  options={roles}
                  getOptionValue={(option) => option.value}
                  placeholder={"Select Role"}
                  value={
                    field?.value !== ""
                      ? roles?.filter((role) => role?.value === field?.value)[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.role ? (
                  <FormHelperText error>{errors?.role?.message}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="role"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

        {/*input for email*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography color={!!formState.errors?.email ? red[700] : ""}>
                  Email*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.email}
                  placeholder="Enter Email"
                />
                {!!formState.errors?.email ? (
                  <FormHelperText error>
                    {errors?.email?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="email"
            control={control}
            defaultValue=""
          />
        </Grid>

        {/*input for password*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.password ? red[700] : ""}
                >
                  Password
                </Typography>
                <OutlinedInput
                  {...field}
                  size={"small"}
                  placeholder={"Enter Password"}
                  type={showPassword ? "text" : "password"}
                  error={!!formState.errors?.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!formState.errors?.password ? (
                  <FormHelperText error>
                    {errors?.password?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="password"
            control={control}
            defaultValue=""
          />
        </Grid>

        {/*input for confirm password*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.confirmPassword ? red[700] : ""}
                >
                  Confirm Password
                </Typography>
                <OutlinedInput
                  {...field}
                  size={"small"}
                  placeholder={"Repeat Password"}
                  type={showPasswordAlt ? "text" : "password"}
                  error={!!formState.errors?.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasswordAlt(!showPasswordAlt)}
                        edge="end"
                      >
                        {showPasswordAlt ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!formState.errors?.confirmPassword ? (
                  <FormHelperText error>
                    {errors?.confirmPassword?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="confirmPassword"
            control={control}
            defaultValue=""
          />
        </Grid>
      </Grid>
      <ErrorMessageShow handleError={handleError} />

      <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
        <Button variant="contained" type={"submit"}>
          Save
        </Button>
      </Stack>
    </form>
  );
};

export default BasicInfoTab;
