import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ProvideAuth } from "./hooks/useAuth";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import CustomizeTheme from "./theme/CustomizeTheme";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <ThemeProvider theme={CustomizeTheme}>
      <HelmetProvider>
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ProvideAuth>
            <Routes />
          </ProvideAuth>
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
